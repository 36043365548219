import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import "./Item.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Upload from 'components/form/Upload';
import TextLong from 'components/form/TextLong';
import { AuthContext } from "providers/AuthProvider";
import { ModelContext } from "providers/ModelProvider";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import {
  useParams,
} from "react-router-dom";
import { createPatch, applyPatch } from 'rfc6902';
import Langswitch from 'components/Langswitch';
import Conf from 'Conf';

const fields=['key','picture'];
const tradFields=['text','html'];

function Item() {
  const lastItem=useRef({});
  const { id }=useParams();
  const { auth } = useContext(AuthContext);
  const { post, modele } = useContext(ModelContext);
  const item=useMemo(()=>(modele.items && modele.items.find(o=>o.id===parseInt(id))) || {},[modele,id]);
  const [modItem, setModItem] = useState({});
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir une clé'};
    return {test:true};
  }
  const valide=validateNom(modItem.key).test;
  const handleOk=()=>{
    if (valide && item && item.id) post('items',modItem,()=>{});
  }
  const handleChange=useCallback((k)=>
  (v)=>{
    setModItem((state)=>{return{...state,[k]:v}});
  },[setModItem]);
  const handleTradChange=useCallback((k,lang)=>
  (v)=>{
    setModItem((state)=>{
      let trads=[];
      const idx=state.trads.findIndex((o)=>o.lang===lang);
      if (idx!==-1) trads=[...state.trads.slice(0,idx),{...state.trads[idx],lang,[k]:v},...state.trads.slice(idx+1)];
      else trads=[...state.trads,{id_item:state.id,lang,[k]:v}];
      return{...state,trads}
    });
  },[setModItem]);
  const handlers=useMemo(()=>{
    const res={};
    for (const field of fields) {
      res[field]=handleChange(field);
    }
    return res;
  },[handleChange]);
  const tradHandlers=useMemo(()=>{
    const res={};
    for (const field of tradFields) {
      for (const lang of Conf.langs) {
        if (!res[field]) res[field]={};
        res[field][lang]=handleTradChange(field,lang);
      }
    }
    return res;
  },[handleTradChange]);
  useEffect(()=>{
    if(!_.isEqual(item,lastItem.current)) {
      const newItem=_.cloneDeep(modItem);
      if (newItem.trads) {
        const beforeUpdate=newItem.trads.filter((o)=>!o.id);
        for (const bu of beforeUpdate) {
          const afterUpdate=item.trads.find((o)=>o.lang===bu.lang);
          if (afterUpdate) {
            const idx=newItem.trads.indexOf(bu);
            newItem.trads.splice(idx,1);
          }
        }
      }
      const patch=createPatch(lastItem.current,item);
      applyPatch(newItem,patch);
      lastItem.current=_.cloneDeep(item);
      setModItem(newItem);
    }
  },[item,modItem]);
  const saved=useMemo(()=>_.isEqual(item,modItem),[item,modItem]);
  return modItem.key ? <Container maxWidth="lg" className="item">
    <div className="spacer"/>
    <Typography variant="h1" component="h1">
      <small>Clé :</small> {modItem.key}
    </Typography>
    <div className="mini-spacer"></div>
    <Button disabled={!valide || saved} onClick={handleOk} variant="contained">Enregistrer</Button>
    <div className='spacer'></div>
    <Grid container spacing={2}>
      {auth.role==='admin' ? <>
      <Grid item xs={6}>
        <Upload name='Image' value={modItem.picture || []} path="items/picture" docId={id} type="image" handleChange={handlers['picture']}/>
      </Grid>
      <Grid item xs={6}>
        <Text name='Clé' autoFocus value={modItem.key || ''} handleChange={handlers['key']} handleSubmit={handleOk} validate={validateNom}/>
      </Grid>
      </> : <Grid item xs={6}>
        {modItem.picture[0] && <div className="preview">
          <img src={Conf.filesUrl+modItem.picture[0].url+'-600'} alt="" className='shadow' crossOrigin="anonymous"/>
        </div>}
      </Grid>}
      <Grid item xs={12}>
      <Langswitch items={Conf.langs.map(lang=>{return tradHandlers['text'][lang] ? {lang,component:<Text key={'text'+lang} name='Text' value={(modItem.trads.find(o=>o.lang===lang)||{}).text || ''} handleChange={tradHandlers['text'][lang]}/>}:{}})}/>
      </Grid>
      <Grid item xs={12}>
      <Langswitch items={Conf.langs.map(lang=>{return tradHandlers['html'][lang] ? {lang,component:<TextLong key={'html'+lang} name='Html' value={(modItem.trads.find(o=>o.lang===lang)||{}).html || ''} handleChange={tradHandlers['html'][lang]}/>}:{}})}/>
      </Grid>
      </Grid>
    <div className="mini-spacer"></div>
    <Button disabled={!valide || saved} onClick={handleOk} variant="contained">Enregistrer</Button>
  </Container> : '';
}

export default Item;
