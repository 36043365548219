const Conf={
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://kimjones-api.lefil.org/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"files/" : "https://kimjones-api.lefil.org/files/",
  renewInterval:5*60*1000,
  langs:[
    'en-US',
    'fr-FR',
    'zh-CN',
    'zh-CHT',
  ],
  roles:[
    {label:'Admin',value:'admin'},
    {label:'Équipe',value:'user'},
  ],
}
export default Conf;
