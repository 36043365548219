import React, { useContext } from "react";
import './Langswitch.scss';
import { SettingsContext } from "providers/SettingsProvider";
import Conf from 'Conf';

function Langswitch({items}) {
  const { appState, setAppState } = useContext(SettingsContext);
  const { lang } = appState;
  const { langs } = Conf;
  const trad=items.find(o=>o.lang===lang) || {};
  return <div className="langswitch">
      {trad.component}
      <div className="tabs">
        {langs.map((l)=><div className={'lang-btn'+(lang===l ? ' active' : '')} key={l} onClick={()=>setAppState(state=>{return{...state,lang:l}})}>{l}</div>)}
      </div>
  </div>;
}

export default Langswitch;
