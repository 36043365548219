import React, { useState, useContext } from "react";
import "./Item.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import {
  useNavigate,
} from "react-router-dom";

const skel={cle:'',picture:{}};
function ItemAdd({open,handleClose}) {
  const navigate = useNavigate();
  const { post } = useContext(ModelContext);
  const [ additem, setAdditem] = useState({...skel});
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir une clé'};
    return {test:true};
  }
  const valide=validateNom(additem.key).test;
  const handleOk=()=>{
    if (valide && additem) post('items',additem,(res)=>{
      const {id} = res[0];
      handleClose();
      setAdditem({...skel});
      navigate('/item/'+id);
    });
  }
  const handleChange=(k,v)=>{
    setAdditem((state)=>{return{...state,[k]:v}});
  }
  return <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Nouvel item</DialogTitle>
      <DialogContent>
        <div className="mini-spacer"></div>
        <Text name='Clé' value={additem.key || ''} autoFocus handleChange={(v)=>handleChange('key',v)} handleSubmit={handleOk} validate={validateNom}/>
        <div className="mini-spacer"></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Ajouter</Button>
      </DialogActions>
    </Dialog>;
}

export default ItemAdd;
