import React, {useContext, useState, useCallback, useMemo} from "react";
import "./Items.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { AuthContext } from "providers/AuthProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ItemAdd from 'components/Item/ItemAdd';
import { confirm } from 'components/Confirm'
import Fab from '@mui/material/Fab';
import {
  NavLink,
} from "react-router-dom";
import Text from 'components/form/Text';
import Utils from 'Utils/Utils';
import Pagination from '@mui/material/Pagination';

const itemsPerPage=24;

function Items() {
  const { modele,del } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const { auth } = useContext(AuthContext);
  const [ add, setAdd ] = useState(false);
  const items=useMemo(()=>modele.items || [],[modele]);
  const { itemsFilter, itemsPage } = appState;
  const setItemsFilter=useCallback((itemsFilter)=>{
    setAppState(state=>{return{...state,itemsFilter,itemsPage:1}});
  },[setAppState]);
  const setItemsPage=useCallback((e,itemsPage)=>{
    setAppState(state=>{return{...state,itemsPage}});
  },[setAppState]);
  const handleAddItem=()=>{
    setAdd(true);
  }
  const tile=useCallback((item)=>{
    const handleDeleteItem=(item)=>{
      confirm({
        titre: 'Supprimer le lieu ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{item.Nom}</strong>&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('items',item.id);
        else console.log('canceled');
      });
    };
    const done=item.trads.filter(o=>(o.text && o.text!=='') || (o.html && o.html!==''));
    return <Grid key={item.id} item xs={6} md={4} lg={3}>
      {auth.role==='admin' ? <Card className="item-card">
        <CardContent className="sort-handle item-content">
          <Typography variant="body">
            {item.key}
          </Typography>
        </CardContent>
        <CardActions>
          <NavLink to={'/item/'+item.id}><Button size="small">Modifier</Button></NavLink>
          <Button size="small" onClick={()=>handleDeleteItem(item)}>Supprimer</Button>
        </CardActions>
        <div className='done'>{done.map(o=><span key={o.lang}>{o.lang}</span>)}</div>
      </Card>
      :
      <NavLink to={'/item/'+item.id}><Card className="item-card">
        <CardContent className="sort-handle item-content">
          <Typography variant="body">
            {item.key}
          </Typography>
        </CardContent>
        <div className='done'>{done.map(o=><span key={o.lang}>{o.lang}</span>)}</div>
      </Card></NavLink>}
    </Grid>;
  },[del,auth.role]);
  const filteredItems=useMemo(()=>{
    let tab = (itemsFilter ? itemsFilter.split(' ') : []).filter((o)=>o!=='').map(word=>Utils.accentsTidyLw(word));
    return items.filter((o)=>{
      let test = true;
      for (const word of tab) {
        test = test && (
          Utils.accentsTidyLw(o.key||'').indexOf(word) !==-1
        );
      };
      return test;
    });
  },[items,itemsFilter]);
  const nb=useMemo(()=>Math.ceil(filteredItems.length/itemsPerPage),[filteredItems]);
  return <>
    <Container maxWidth="lg" className="items">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Items
      </Typography>
      <div className='spacer'></div>
      <Text name='Rechercher' autoFocus value={itemsFilter} handleChange={setItemsFilter} clear/>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={itemsPage} onChange={setItemsPage}/>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {filteredItems.filter((o,i)=>i>=(itemsPage-1)*itemsPerPage && i<itemsPage*itemsPerPage).map((item)=>tile(item))}
      </Grid>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={itemsPage} onChange={setItemsPage}/>
    </Container>
    {auth.role==='admin' && <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddItem}>
        <AddIcon />
      </Fab>
      </Container>
    </div>}
    <ItemAdd open={add} handleClose={()=>setAdd(false)}/>
  </>
}

export default Items;
